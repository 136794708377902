import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../../components/PageWrapper";
import { Section, Title, Text } from "../../components/Core";

// import TeamListTall from "../sections/the-team/TeamListTall"; // <-- alternative PFP Layout
import TeamListWide from "../../sections/the-team/TeamListWide";
import imgBg from "../../assets/image/jpeg/bg-team.jpg";

// TEAM : WRAP
const TeamSection = styled(Section)`
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  background: #000000 url('${imgBg}') center center/contain no-repeat;
`;


// TEAM : SUMMARY HEADING
const TeamSummary = styled(Row)`
  position: relative;
`;

const SummaryTextTitle = styled(Title)`
  color: ${({ theme }) => theme.goatColors.text };
  font-family: 'goat-roboto','roboto','lato', sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-bottom: 20px;
`;

const SummaryTextBody = styled(Text)`
  color: ${({ theme }) => theme.goatColors.text };
  font-family: 'goat-roboto','roboto','lato', sans-serif;
  font-weight: 300;
  font-style: normal;
  padding-bottom: 20px;
`;

const TheTeam = () => {
  return (
    <>
      <PageWrapper footerDark>
        <TeamSection className="pb-0">

          <Container>
            <TeamSummary className="team-summary justify-content-center text-center">
              <Col lg="6">
                <SummaryTextTitle variant="hero">The Team</SummaryTextTitle>
                <SummaryTextBody>
                  Curators of the System
                </SummaryTextBody>
              </Col>
            </TeamSummary>
          </Container>

          {/* ALTERNATIVE LAYOUT OPTIONS:
            <TeamListTall /> 
          */}
          <TeamListWide />
        
        </TeamSection>
      </PageWrapper>
    </>
  );
};
export default TheTeam;
