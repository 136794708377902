import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { Title, Box, Text } from "../Core";


const CardWrap = styled.div`
  flex-wrap: wrap;
  justify-content: center;
`;

const TeamImg = styled(Box)`
  max-width: 210px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

const TeamText = styled(Box)`
  padding-left: 30px;
  color: ${({ theme }) => theme.goatColors.panelText};
`;




const TeamCard = ({
  img,
  imgBrand,
  preTitle,
  subTitle,
  title,
  link,
  children,
  readMore,
  ...rest
}) => (
  <CardWrap className="d-flex" {...rest}>
    
    <TeamImg>
      <Link to={link || "/"}>
        <img src={img} alt="" className="team-card-img w-100 img-fluid" />
        <img src={imgBrand} alt="" className="team-card-logo img-fluid" />
      </Link>
    </TeamImg>

    <TeamText>
      <Title className="team-card-title" variant="card" mb="10px">
        {title}
      </Title>

      <Text className="team-card-subtitle" variant="card" mb="12px">
        {subTitle}
      </Text>

      <Text className="team-card-body" fontSize={2} lineHeight={1.75} mb="0">
        {children}
      </Text>
    </TeamText>

  </CardWrap>
);

export default TeamCard;
