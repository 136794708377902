import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { Section } from "../../components/Core";
import TeamCard from "../../components/TeamCard";

import imgTeamMember1 from "../../assets/image/placeholder/placeholder_1.png";
import imgTeamMember2 from "../../assets/image/placeholder/placeholder_2.png";
import imgTeamMember3 from "../../assets/image/placeholder/placeholder_3.png";

const TeamListWide = () => (
  <>
    {/* <!-- Feature section --> */}
    <Section className="position-relative">
      <Container>
        <Row className="align-items-center justify-content-center">
          <Col lg="12" className="mb-5 mb-lg-25">
            <TeamCard
              img={imgTeamMember1}
              // imgBrand={imgTeamMember1Logo} // Uncomment to add a title 
              title="Member #1"
              subTitle="Artist"
              link="https://google.com"
            >
              The vison lead guides you on the journey.
            </TeamCard>
          </Col>
          <Col lg="12" className="mb-5 mb-lg-25">
            <TeamCard
              img={imgTeamMember2}
              // imgBrand={imgTeamMember1Logo} // Uncomment to add a title 
              title="Member #2"
              subTitle="Developer"
              link="https://google.com"
            >
              The vison lead guides you on the journey.
            </TeamCard>
          </Col>
          <Col lg="12" className="mb-5 mb-lg-25">
            <TeamCard
              img={imgTeamMember3}
              // imgBrand={imgTeamMember1Logo} // Uncomment to add a title 
              title="Member #3"
              subTitle="Community Lead"
              link="https://google.com"
            >
              The vison lead guides you on the journey.
            </TeamCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
);

export default TeamListWide;
